$t-Color__black: #060606;
$t-Color__gray--dark-700: #354046;
$t-Color__grey: #ACACAC;
$t-Color__red: #D71A11;
$t-Color__red--dark: #960800;
$t-Color__green: #54BD14;

$t-Color__pattern__gray: #68696D;
$t-Color__pattern__gray--light: #D7D9DA;
$t-Color__pattern__gray--lightest: #F5F5F4;

//main colors:
$t-Color__primary: $t-Color__red; $__hex: #D71A11;
$t-Color__secondary: $t-Color__green; $__hex: #54BD14;
$t-Color__accent: $t-Color__green; $__hex: #54BD14; //not using accent for now
$t-Color__auxiliary: $t-Color__green; $__hex: #54BD14; // 4th color when needed (e.g. ontology) -- not used for now

//lightness variations
$t-Color__primary--light: lighten($t-Color__primary, 15); $__hex: #f04e47;
$t-Color__secondary--light: lighten($t-Color__secondary, 10); $__hex: #6ae71d;

$t-Color__primary--dark: darken($t-Color__primary, 15); $__hex: #8e110b;
$t-Color__secondary--dark: darken($t-Color__secondary, 10); $__hex: #3d8a0f;

$t-Color__primary--darkest: darken($t-Color__primary, 20); $__hex: #5f0b07;
$t-Color__secondary--darkest: darken($t-Color__primary, 20); $__hex: #295c0a;

//desaturated (grey) variations of the primary color
$t-Color__primary__gray: desaturate($t-Color__primary, 30); $__hex: #b43a34;
$t-Color__primary__gray--dark: darken($t-Color__primary__gray, 10); $__hex_color: #8a2d28;
$t-Color__primary__gray--light: lighten($t-Color__primary__gray--dark, 10); $__hex: #cd5751;

//greys
$t-Color__tan: $t-Color__pattern__gray--lightest;
$t-Color__gray--medium-500: #babbbd;
$t-Color__gray--dark-600: #808184;
$t-Color__gray--dark-700: #57585a;
$t-Color__gray--dark-800: #404143;

//alert and message colors:
$t-Color__success: $t-Color__secondary;
$t-Color__alert: #eb008a;
$t-Color__warning: #ffc107;
$t-Color__info: $t-Color__pattern__gray;

//TODO COLAB-2352: We don't need these anymore once we remove c-Alert
$t-Color__alert--light: #f6dfec;
$t-Color__info__background: $t-Color__primary__gray--light;
$t-Color__success__background: $t-Color__secondary--light;

//standard text colors
$t-Color__text--light: white;
$t-Color__text--dark: $t-Color__gray--dark-800;

//outline view TODO: see if we can adapt this to use our colors
$t-Color__outline__level1: #e0dacb; // background slevel1
$t-Color__outline__level2: #d1cbbf; // background slevel2
$t-Color__outline__level3: #c1bcb2; // background slevel3
$t-Color__outline__level4: #aaa69f; // background slevel4-6


// ====
// changes in component variables

@import url(https://fonts.googleapis.com/css?family=Fira+Sans:400,500,300);

$t-Typography__fontWeight--light: 300;
$t-Typography__fontWeight--regular: 400;
$t-Typography__fontWeight--medium: 500;

$b-Typography__fontFamily: "Fira Sans", Arial, sans-serif;
$b-Typography__capitalization__h1: uppercase;
$b-Typography__capitalization__h2: uppercase;
$b-Typography__fontSize__h1: 33px;
$b-Typography__fontSize__h2: 31px;
$b-Typography__fontSize__h3: 24px;
$b-Typography__fontSize__h4: 17px;
$b-Typography__fontSize__h5: 17px;
$b-Typography__fontSize__h6: 15px;
$b-Typography__textColor__h1: $t-Color__primary;
$t-Typography__fontWeight__h2: $t-Typography__fontWeight--light;
$t-Typography__fontWeight__h3: $t-Typography__fontWeight--regular;
$t-Typography__fontWeight__h5: $t-Typography__fontWeight--light;
$t-Typography__fontWeight--bold: $t-Typography__fontWeight--medium;

$cb-navbar__background: white;
$cb-navbar__hasBoxShadow: true;
$c-Header__menu__li__a__fontWeight: $t-Typography__fontWeight--regular;
$c-Header__menu__right__li__a__fontWeight: $t-Typography__fontWeight--regular;
$c-Header__menu__li__a__textColor: $t-Color__gray--dark-600;
$c-Header__menu__li__hoverColor: $t-Color__red;


$c-Footer__background: white;
$c-Footer__menu__li__a--hover__color: $t-Color__primary;
$c-Footer__menu__background: linear-gradient(to bottom, lighten($t-Color__gray--dark-600, 5%), darken($t-Color__gray--dark-600, 5%));
$c-Footer__menu__boxShadow: inset 0px 5px 12px lighten($t-Color__gray--dark-700, 10%);

$c-TitleBar__backgroundColor: $t-Color__red;
$c-TitleBar__borderBottom: none;
$c-TitleBar__element--active__capitalization: uppercase;
$c-TitleBar__separator__color: white;
$c-TitleBar__textColor: white;
$c-TitleBar__element--disabled__textColor: $t-Color__pattern__gray--light;
$c-TitleBar__a__textColor: white;
$c-TitleBar__a__textColor--hover: $t-Color__gray--medium-500;
$c-TitleBar__a__fontWeight: $t-Typography__fontWeight--regular;
$c-TitleBar__contestSummary__backgroundColor: $t-Color__gray--dark-700;
$c-TitleBar__capitalization: uppercase;

$p-About__left__li__a__fontWeight: $t-Typography__fontWeight--regular;
$p-About__left__li__a__padding--left: 15px;
$p-About__left__li__ul__li__a__padding--left: 33px;
$p-About__left__li__a__current__borderWidth--left: 5px;
$p-About__left__li__a__current__padding--left: 10px;
$p-About__checkout__backgroundColor: $t-Color__pattern__gray--light;
$p-About__contribute__background: url(/images/about/getinvolved-top_illustration.png) no-repeat;
$p-About__ge1__background: url(/images/about/getinvolved-row-01.png) no-repeat 50px 15px;
$p-About__ge2__background: url(/images/about/getinvolved-row-02.png) no-repeat 45px 0px;
$p-About__ge3__background: url(/images/about/getinvolved-row-03.png) no-repeat 40px 0px;
$p-About__ge4__background: url(/images/about/getinvolved-row-04.png) no-repeat 45px 15px;
$p-About__getinvolved__backgroundColor: white;
$p-About__approachIllustration__p__fontWeight: $t-Typography__fontWeight--light;

$c-ContestBox__metaFlag__capitalization: uppercase;
$c-ContestBox__metaFlag__boxShadow: 0px 0px 5px $t-Color__gray--medium-500;
$c-ContestBox__metaFlag--color__border: none;
$c-ContestBox__border: none;
$c-ContestBox--hover__boxShadow: 0 0 15px $t-Color__gray--medium-500;

$p-Contests__userslist__a__fontWeight: $t-Typography__fontWeight--regular;
$p-Contests__userslist__td--g__background: $t-Color__pattern__gray--lightest;
$p-Contests__usersFilter__paddingRight: 0px;

$p-community__colab__th__background: $t-Color__primary;
$p-community__colab__th__textColor: white;
$p-community__commList__marginTop: 7px;
$p-community__commList__h2__paddingBottom: 6px;
$p-community__commList__h2__borderBottom: 1px solid $t-Color__gray--medium-500;
$p-community__commList__h2__fontWeight: $t-Typography__fontWeight--regular;
$p-community__discHead__width: 680px;
$p-community__infoLeft__fontStyle: normal;
$p-community__infoLeft__capitalization: uppercase;
$p-community__infoLeft__fontSize: 20px;
$p-community__infoLeft__fontWeight: $t-Typography__fontWeight--light;
$p-community__infoLeft__paddingTop: 10px;
$p-community__infoLeft__span__fontSize: 20px;
$p-community__infoLeft__span__textColor: $t-Color__primary;
$p-community__infoLeft__span__paddingRight: 0px;
$p-community__infoLeft__span__fontWeight: $t-Typography__fontWeight--regular;

$p-Contests__contestDetail__backgroundColor: $t-Color__pattern__gray--lightest;
$p-Contests__contestDetail__heading__background: $t-Color__pattern__gray--light;

$p-proposal__header__h1__textColor: $t-Color__red;
$p-proposal__propbox__h4__fontWeight: $t-Typography__fontWeight--regular;
$p-proposal__propbox__titleAuthor__width: 602px;
$p-proposal__propbox__padding: 15px 0px 30px 14px;
$p-Proposal__sidebar__sectionBackgroundColor: white;
$p-proposal__votebox__top__left: 0px;
$p-proposal__votebox__marginTop: 35px;
$p-proposal__contest__padding--top--bottom: 20px;
$p-proposal__left__h2__textColor: $t-Color__black;
$p-proposal__left__h2__fontWeight: $t-Typography__fontWeight--light;
$p-proposal__propbox__judgementActions__featured__titleAuthor__width: 415px;
$p-proposal__propbox__judgementActions__titleAuthor__width: 445px;

$p-Proposal__sidebar__subscribe__capitalization: uppercase;
$l-BoxLayout__striped__backgroundColor: $t-Color__pattern__gray--light;

$p-community__left__lineHeight: default;

$t-Table__borderColor: $t-Color__pattern__gray--light;
$t-Table__thead__backgroundColor: $t-Color__primary;
$t-Table__stripedColumn__background: $t-Color__pattern__gray--lightest;
$c-TableGrid__cell__highlight_backgroundColor: white;

$p-ContestProposals__list__element--proposalname__width: 605px;
$p-ContestProposals__list__element__border: 1px solid;
$p-ContestProposals__list__element--comments__background__top: 10px;
$p-ContestProposals__list__element--judgeFellow__width: 158px;
$p-ContestProposals__list__element--judgeFellow__border--left: 1px solid;
$p-ContestProposals__list__element--contributor__width: 146px;
$p-ContestProposals__list__element--proposalname__judgeActions__width: 449px;

$p-Members__list__element__fontWeight: $t-Typography__fontWeight--regular;

$cb-list-group__itemBackground--odd: $t-Color__pattern__gray--lightest;

$c-Box__shadow: none;
$c-Box__messaging__padding--top: 15px;
$c-Box__background__color--dark: #354046;
$c-Box__border: none;

$c-TabBar__background: white;
$c-TabBar__borderTop: 1px solid $t-Color__gray--dark-600;
$c-TabBar__paddingTop: 35px;

$c-TabBar__tab__paddingTop: 20px;
$c-TabBar__tab__paddingBottom: 19px;
$c-TabBar__tab__paddingSides: 25px;
$c-TabBar__tab__background: rgba(255, 255, 255, 0);
$c-TabBar__tab__shadow--active: inset 0 -4px 0 $t-Color__green;
$c-TabBar__tab__fontWeight: $t-Typography__fontWeight--regular;
$c-TabBar__tab__borderRadius: 0px;
$c-TabBar__tab__borderTop: none;
$c-TabBar__tab__borderRight: 1px solid $t-Color__gray--dark-600;
$c-TabBar__tab__borderLeft: none;
$c-TabBar__tab__borderLeft--first: 1px solid $t-Color__gray--dark-600;
$c-TabBar__tab__borderRight--last: 1px solid $t-Color__gray--dark-600;
$c-TabBar__tab__fontSize: 17px;
$c-TabBar__tab__textColor: $t-Color__primary;
$c-TabBar__tab__textColor--active: $t-Color__gray--dark-800;
$b-TabBar__tab__marginRight: 0;
$c-TabBar__tab--first__margin--left: 30px;
$c-TabBar__tab__active__border--right: 1px solid $t-Color__gray--dark-600;
$c-TabBar__tab__active__border--right--left: none;

$c-TabBar__tab__count__background: $t-Color__pattern__gray--light;

$p-userprofile__comm_rightcol-title2__height: auto ;

$c-Helpbox__borderRadius: 0px;
$c-Helpbox__borderColor: $t-Color__gray--dark-700;
$c-Helpbox__header__padding: 8px 10px 4px 19px;
$c-Helpbox__header__background: $t-Color__gray--dark-700;
$c-Helpbox__header__title__textColor: white;
$c-Helpbox__header__title__capitalization: uppercase;
$c-Helpbox__text__fontWeight: $t-Typography__fontWeight--light;
$c-Helpbox__header__close__marginTop: 0px;

$c-Count__fontSize: 1.125rem;
$c-Count__capitalization: uppercase;
$c-Count__number__color: $t-Color__primary;
$c-Count__number__fontSize: 1.25rem;
$c-Count__number__fontWeight: $t-Typography__fontWeight--regular;

$t-Border__radius: 1.5rem;
$t-Border__radius--large: 1.75rem;
$t-Border__radius--small: 1rem;
